@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$input-border-radius: none !default;
$input-border: 2px solid $active-color !default;
$input-border-color: $grey-medium !default;
$input-shadow-box: none !default;

.control-group {
	position: relative;
	box-sizing: border-box;

	&__note {
		margin-left: 5px;
		margin-top: 5px;
		color: $grey-dark;
		font-size: 1.2rem;
	}

	&__label {
		position: absolute;
		top: 15px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $black;
		pointer-events: none; // fix empeche de saisir sur device ios

		.horizontal-dot-loader {
			margin-left: 5px;
		}
	}

	&__loader {
		height: 50px;
		width: 100%;
		padding-top: 27px;
		padding-left: 20px;
		box-sizing: border-box;
		border: 1px solid $input-border-color;
		outline: none;
		border-radius: $input-border-radius;
	}

	&__input {
		@extend %text-bold;
		height: 50px;
		width: 100%;
		padding-top: 0;
		padding-bottom: 0; // the content is not centered on ios
		padding-left: 15px;
		box-sizing: border-box;
		border: 1px solid $input-border-color;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $input-border-radius;
		box-shadow: $input-shadow-box;
		-webkit-appearance: none; // delete top big border in ios

		@include gridle_state(md) {
			&:focus,
			&:hover {
				border: $input-border;
			}
		}

		&:-webkit-autofill + .control-group__label  {
			@extend %input-label-touched;
		} 
		&::-ms-reveal {
			display: none;
		}
	}

	&__helper {
		display: inline-block;
		margin-top: 10px;
	}

	&--required {
		.control-group__label {
			&:after {
				content: "*";
			}
		}
	}

	&--error {
		.control-group__input {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px rgba(197, 6, 18, 0.6);
			border: 1px solid $color-error;
			background: #fff;
		}

		.control-group__helper {
			color: $color-error;
		}
	}

	&--touched {
		.control-group__label {
			@extend %input-label-touched;
		}
	}

	&--disabled {
		.control-group {
			&__label {
				color: $grey-dark;
			}

			&__input {
				color: $grey-dark;
				-webkit-text-fill-color: $grey-dark; // le style disabled de safari est trop peu lisible
				opacity: 1; // le style disabled de safari est trop peu lisible

				@include gridle_state(md) {
					&:focus,
					&:hover {
						border: 1px solid $input-border-color;
						cursor: not-allowed;
					}
				}
			}
		}
	}

	&--loading {
		.control-group {
			&__label {
				color: $active-color;
			}
		}

		@include gridle_state(md) {
			&:focus,
			&:hover {
				cursor: wait;
			}
		}
	}

	input {
		&:hover,
		&:focus {
			&::-webkit-calendar-picker-indicator {
				display: none;
			}
		}
	}
}
