@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.reason-to-book-block {
	&__title {
		margin-bottom: 32px;

		@include gridle_state(md) {
			margin-bottom: 24px;
		}
	}
}
