@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$amount-font-weight: normal !default;
$amount-color: $color-primary !default;

.amount {
	color: $amount-color;
	display: inline-flex;
	align-items: baseline;

	&__prefix {
		@extend %text-secondary;
		margin-right: 5px;
	}

	&__suffix {
		@extend %font-bold;
		margin-left: 5px;
	}

	&__value {
		@extend %font-bold;
	}
}
