@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$rtb-picto-color: $amount-color !default;

.reason-to-book {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	&__picto {
		display: flex;
		color: $rtb-picto-color;
		margin-right: 12px;

		i {
			width: 24px;
			height: 24px;
			background-color: currentColor;
			mask-position: center;
			mask-repeat: no-repeat;
			mask-size: contain;
			-webkit-mask-position: center;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-size: contain;
		}

		@include gridle_state(md) {
			margin-right: 16px;
		}
	}

	&__title {
		@extend %text-bold;
		font-size: 1.6rem;
		margin-bottom: 8px;
		line-height: 120%;
	}

	&__description {
		font-size: 1.2rem;
		line-height: 140%;
		word-break: break-word;
	}
}
