/***************************
 * = COULEURS
 ***************************/

$color-primary: #53a318;
$color-primary-dark: #3e7a12;
$color-primary-light: #73de25;
$color-secondary: #0081e3;
$color-secondary-dark: #096fbb;
$color-secondary-light: #34a4f9;
$color-tertiary: #6650d7;
$color-tertiary-dark: #4d3aad;
$color-tertiary-light: #846ef7;
$black: #333333;
$grey-dark: #727578;
$grey-light: #f6f7f8;
$grey-medium: #bbbcbc;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%beautify {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%font-regular {
	@extend %beautify;
	font-family: "opensans", sans-serif;
}

%font-bold {
	@extend %beautify;
	font-family: "opensans-bold", sans-serif;
}

// Gros titre (nombre de vente sur listing) et Votre Hotel sur la page listing
%h1 {
	@extend %font-regular;
	font-size: 2.5rem;
	letter-spacing: 1px;
	text-align: center;

	@include gridle_state(md) {
		letter-spacing: 2.5px;
	}
}

// Chambres, restaurants, lifestyle, Bon savoir sur la fiche produit
%h2 {
	@extend %font-bold;
	font-size: 2.1rem;
}

// nom des chambres et restaurants (titre des photos)
%h3 {
	@extend %font-bold;
	font-size: 1.8rem;

	@include gridle_state(md) {
		font-size: 2rem;
	}
}

// details de cette offre, prix de la quote et paiement
// label des radio button
// titre des label du moteur de recherche
%h4 {
	@extend %font-bold;
	font-size: 1.6rem;
}

%subheading {
	@extend %font-bold;
	font-size: 1.5rem;

	@include gridle_state(md) {
		font-size: 3rem;
	}
}

%text {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%headline {
	@extend %font-bold;
	font-size: 17px;
	line-height: 1.23;
	letter-spacing: 2.2px;
	color: white;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-secondary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

%master-button {
	text-transform: initial;
	border-radius: 5px;
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-big: 0;
$border-radius-small: 5px;
$border-radius-top: $border-radius-big $border-radius-big 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$amount-color: $color-primary;
$header-background-color: $color-primary;
$header-text-transform: uppercase;
$header-border-bottom: 1px solid $grey-medium;
$header-text-color: white;
$header-menu-active-color: $black;

$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-primary;
$common-box-shadow: none;

$badge-background-color: $color-primary;

$payment-banner-background-color: $color-primary;

/***************************
 * = FORM
 ***************************/

$active-color: $color-primary;
$active-color-dark: $color-primary-dark;
$active-color-light: $color-primary-light;
$input-border-color: $grey-medium;
$input-border: 2px solid $active-color;
$input-border-radius: $border-radius-small;
$radio-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: none;
$button-primary-background-color: $color-primary;
$button-primary-background-color-dark: $color-primary-dark;
$button-box-shadow: none;

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: none;

$deal-background-color: $color-primary;
$product-deal-text-color: white;
$product-deal-background-color: $color-primary;

$offer-mobile-background-color: $active-color;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $grey-dark;
$bloc-contact-text-color: white;

$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$datepicker-background-color: $black;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $grey-dark;
$booking-amount-upgrade-price-color: $black;
$booking-item-margin: 15px;

$quotation-price-detail-toggle-button-background: $black;
$quotation-price-detail-total-background: $black;
$quotation-price-detail-total-border: 1px solid $black;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $color-tertiary;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: $border-radius-small;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-small;
$flight-filter-background-color: $active-color;
$flight-filter-button-border: none;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

$quotation-cabins-margin: 0;
$quotation-cabins-filter-header-background: $grey-light;
$quotation-cabins-filter-header-border: none;
$quotation-cabins-filter-border-bottom: 2px solid $flight-filter-background-color;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-first-color: $color-primary;
$insurances-radio-second-color: $color-tertiary;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-primary;

/***************************
 * = LISTING
 ***************************/
$product-footer-border: none;
$product-deal-public-price-line-through-color: $black;
$product-name-text-transform: none;
$product-intemporelle-color: $color-primary;
$listing-background-color: $grey-light;
$listing-actionbar-title-display-on-mobile: block;
$product-cta-button-radius: 50%;
$listings-product-background-color: $grey-light;
$listing-header-background-color: $grey-light;
$colored-product-badge-background-color: $color-primary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 0;
$listing-time-border-radius: 50px;
$listing-time-background-color: rgba(51, 51, 51, 0.65);

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1.2rem;
	color: $active-color;
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$mobile-auth-background-color: $color-primary;
