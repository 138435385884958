@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
@import "~swiper/src/swiper.scss";

.reason-to-book-carousel {
	position: relative;
	padding-bottom: 32px;

	@include gridle_state(md) {
		padding: 0 48px;
	}

	.swiper-container {
		height: 100%;
	}

	&__button {
		position: absolute;
		top: 0;
		display: flex;
		align-items: center;
		height: 100%;
		z-index: 10;

		&--next {
			right: 0;
		}

		&--previous {
			left: 0;
		}
	}

	.carousel-dots {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-top: 16px;
		margin-right: auto;
		margin-left: auto;

		&__dot {
			background-color: $grey-medium;

			&--active {
				background-color: $grey-dark;
			}
		}
	}
}
